import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Button from "../components/common/Button"
import { Link } from "gatsby"

const Wrapper = styled.section`
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;

  display: flex;
  align-items: center;

  min-height: calc(100vh - 75px - 280px);

  .custom-button {
      max-width: 350px;
  }
`

const Danke = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Danke für dein Interesse</title>
      </Helmet>

      <Layout>
        <Wrapper>
          <div className="content">
            <h1>Danke für dein Interesse</h1>
            <p>
              Wir bearbeiten deine Anfrage so schnell es geht,
              <br />
              für gewöhnlich Antworten wir spätestens am nächsten Werktag.
            </p>
            <p>In der Zwischenzeit könnte dich folgendes Interesieren:</p>
            <Button className="custom-button">
              <Link to="/radwege">Der Radweg von morgen</Link>
            </Button>
            <Button className="custom-button" tertiary>
              <Link to="/">Zurück zur Homepage</Link>
            </Button>
          </div>
        </Wrapper>
      </Layout>
    </>
  )
}

export default Danke
